import { config } from "./WebIMConfig";
import websdk from "easemob-websdk";
import store from '@/vuex/index'
// 初始化IM SDK
let WebIM = {};
WebIM = window.WebIM = websdk;
WebIM.config = config;
WebIM.conn = new WebIM.connection({
    appKey: WebIM.config.appkey,
    isHttpDNS: WebIM.config.isHttpDNS,
    isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
    https: WebIM.config.https,
    url: WebIM.config.xmppURL,
    apiUrl: WebIM.config.apiURL,
    isAutoLogin: true,
    heartBeatWait: WebIM.config.heartBeatWait,
    autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
    autoReconnectInterval: WebIM.config.autoReconnectInterval,
    isStropheLog: WebIM.config.isStropheLog,
    delivery: WebIM.config.delivery
});
if (!WebIM.conn.apiUrl) {
    WebIM.conn.apiUrl = WebIM.config.apiURL;
}

WebIM.conn.listen({
    onOpened: () => {},
    //收到文本消息
    onTextMessage: res => {
        if (res.from.match('patient') || res.to.match('patient')) {
            store.commit('consult/setMsg', res);
        } else if (res.from.match('doctor') || res.to.match('doctor')) {
            store.commit('service/setMsg', res);
        }else if(res.from.match('shop') || res.to.match('shop')){
            store.commit('shopService/setMsg', res);
        }

    },
    //收到表情消息
    onEmojiMessage: function(message) {},
    //收到图片消息
    onPictureMessage: res => {
        if (res.from.match('patient') || res.to.match('patient')) {
            store.commit('consult/setMsg', res);
        } else if (res.from.match('doctor') || res.to.match('doctor')) {
            store.commit('service/setMsg', res);
        }else if(res.from.match('shop') || res.to.match('shop')){
            store.commit('shopService/setMsg', res);
        }
    },
    //收到命令消息
    onCmdMessage: (res) => {
        if (res.from.match('patient') || res.to.match('patient')) {
            store.commit('consult/setMsg', res);
        } else if (res.from.match('doctor') || res.to.match('doctor')) {
            store.commit('service/setMsg', res);
        }else if(res.from.match('shop') || res.to.match('shop')){
            store.commit('shopService/setMsg', res);
        }
    },
    //音频消息
    onAudioMessage: function(res) {
        let options = {
            url: res.url,
            headers: {
                Accept: "audio/mp3"
            },
            onFileDownloadComplete: (response) => {
                let objectUrl = WebIM.utils.parseDownloadResponse.call(WebIM.conn, response);
                let data = {
                    url: objectUrl,
                    filename: "audio",
                    to: res.to,
                    from: res.from,
                    time: res.time,
                    id: res.id,
                    duration: res.length,
                    contentsType: "VOICE",
                    type: "groupchat"
                }
                if (res.from.match('patient') || res.to.match('patient')) {
                    store.commit('consult/setMsg', res);
                } else if (res.from.match('doctor') || res.to.match('doctor')) {
                    store.commit('service/setMsg', res);
                }else if(res.from.match('shop') || res.to.match('shop')){
                    store.commit('shopService/setMsg', res);
                }
            },
            onFileDownloadError: function() {
                console.log("音频下载失败");
            }
        };
        WebIM.utils.download.call(WebIM.conn, options);
    },
    onOnline: function() {}, //本机网络连接成功
    onOffline: function() {}, //本机网络掉线
    onError: function(res) {
        store.commit('consult/setError', res);
        store.commit('service/setError', res);
    }, //失败回调
    onReceivedMessage: function(message) {}, //收到消息送达服务器回执
    onDeliveredMessage: function(message) {}, //收到消息送达客户端回执
    onReadMessage: function(message) {} //收到消息已读回执
});
export default WebIM;