<template>
  <!-- 商城客服 -->
  <div class="big service">
    <div>
      <div class="container flex between">
        <div class="left bb">
          <div class="top flex bb">
            <el-input
              v-model="keyword1"
              placeholder="用户名字/手机号"
              size="small"
              clearable
              @keyup.enter.native="searchPatient"
              @input="clearPatient"
            />
            <el-button type="primary" size="small" @click="searchPatient"
              >查询</el-button
            >
          </div>
          <template v-if="!searchShow">
            <!-- 对话列表 -->
            <div class="count">用户数：({{(this.userList.length&lt;9999)? this.userList.length:9999}})</div>
            <div class="doctor_list">
              <div
                class="flex between box"
                v-for="item in userList"
                :key="item.user_id"
                @click="selectUser(item)"
                :class="item.current ? 'current' : ''"
                v-show="item.con_username"
              >
              <div class="img_box" style="width:167px">
                <img :src="item.head_img" style="width:37px;height: 37px;border-radius: 50%;margin-right: 9px;display:inline-block;">
                <div class="d1" :title="item.nick_name">{{ item.nick_name }}</div>
              </div>
                <div class="d2">
                  {{ item.msg_time || "" }}
                  <!-- <span :class="item.active ? '' : 'opacity'">●</span> -->
                </div>
              </div>
            </div>
          </template>
          <template v-if="searchShow">
            <!-- 搜索列表 -->
            <div class="doctor_list" style="margin-top: 5px">
              <div
                class="flex between box"
                v-for="item in searchList"
                :key="item.con_username"
                @click="selectUser(item, 2)"
              >
                <div class="img_box" style="width:167px">
                  <img :src="item.head_img" style="width:37px;height: 37px;border-radius: 50%;margin-right: 9px;display:inline-block;">
                  <div class="d1" :title="item.nick_name">{{ item.nick_name }}</div>
                </div>
                <div class="d2">
                  {{ item.msg_time == 0 ? "" : item.msg_time }}
                </div>
              </div>
            </div>
            <div v-show="searchList.length == 0">暂无匹配的用户！</div>
          </template>
        </div>
        <div class="right bb">
          <template v-if="userInfo.con_username">
            <div class="dialogBox" ref="dialogBox" v-loading="loading">
              <div
                v-for="(item, index) in dialogueData"
                :key="item.con_username"
              >
                <p
                  class="time"
                  v-if="
                    index == 0 ||
                    (index > 0 &&
                      item.time - dialogueData[index - 1].time > 1000 * 60)
                  "
                >
                  {{ formatDate(item.time) }}
                </p>
                <!-- 自己发送的文本消息 -->
                <div class="patient-dialogue flex" v-if="item.showType == 1">
                  <img src="@/assets/img/kefu.svg" alt class="patient-img" />
                  <div class="text">{{ item.data }}</div>
                </div>
                <!-- 用户发送的文本消息 -->
                <div class="doctor-dialogue flex" v-if="item.showType == 2">
                  <img
                    :src="userInfo.head_img ? userInfo.head_img : doc_img"
                    alt
                    class="patient-img"
                  />
                  <div class="text">{{ item.data }}</div>
                </div>
                <!-- 用户发送的图片消息 -->
                <div class="doctor-dialogue flex" v-if="item.showType == 4">
                  <img
                    :src="userInfo.head_img ? userInfo.head_img : doc_img"
                    alt
                    class="doctor-img"
                  />
                  <el-image
                    :src="item.url"
                    :preview-src-list="[item.url]"
                    class="my-img"
                  ></el-image>
                </div>
                <!-- 自己发送的图片消息 -->
                <div class="patient-dialogue flex" v-if="item.showType == 3">
                  <img src="@/assets/img/kefu.svg" alt class="patient-img" />
                  <el-image
                    :src="item.url"
                    :preview-src-list="[item.url]"
                    class="my-img"
                  ></el-image>
                </div>
                <!-- 用户发送的语音 -->
                <div
                  class="doctor-dialogue flex"
                  v-if="item.showType == 6"
                  @click="playAudio(item, index)"
                >
                  <img
                    :src="userInfo.head_img ? userInfo.head_img : doc_img"
                    alt
                    class="doctor-img"
                  />
                  <div
                    class="text flex between align-center"
                    style="width: 2rem"
                  >
                    <img
                      src="@/assets/img/music.png"
                      alt
                      style="width: 20px"
                      v-show="!item.playing"
                    />

                    <img
                      src="@/assets/img/play.gif"
                      alt
                      style="20px"
                      v-show="item.playing"
                    />

                    <span v-show="item.duration != 0"
                      >{{ item.duration }}"</span
                    >
                  </div>
                </div>
                <!-- 自己发送的推荐商品 -->
                <div class="patient-dialogue flex" v-if="item.showType == 5">
                  <img src="@/assets/img/kefu.svg" alt class="patient-img" />
                  <div class="flex mydoctorInfo">
                    <img :src="item.ext.picture" />
                    <div class="flex column column-center">
                      <div class="d1" style="align-self: start">
                        {{ item.ext.title }}
                      </div>
                      <div class="d2" style="align-self: start">
                        <span>原价：{{ item.ext.price }}</span>
                        <span
                          v-if="
                            item.ext.promotion_price &&
                            item.ext.promotion_price != 0 &&
                            item.ext.act_type == 1
                          "
                          >活动价：{{ item.ext.promotion_price }}</span
                        >
                        <span
                          v-if="
                            item.ext.promotion_price &&
                            item.ext.promotion_price != 0 &&
                            item.ext.act_type == 2
                          "
                          >秒杀价：{{ item.ext.promotion_price }}</span
                        >
                        <span
                          v-if="
                            item.ext.member_price && item.ext.member_price != 0
                          "
                          >会员价：{{ item.ext.member_price }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 用户发送的推荐商品 -->
                <div class="doctor-dialogue flex" v-if="item.showType == 7">
                  <img
                    :src="userInfo.head_img ? userInfo.head_img : doc_img"
                    class="doctor-img"
                  />
                  <div class="flex doctorInfo">
                    <img :src="item.ext.picture" />
                    <div class="flex column column-center">
                      <div class="d1" style="align-self: start">
                        {{ item.ext.title }}
                      </div>
                      <div class="d2" style="align-self: start">
                        <span>原价：{{ item.ext.price }}</span>
                        <span
                          v-if="
                            item.ext.promotion_price &&
                            item.ext.promotion_price != 0 &&
                            item.ext.act_type == 1
                          "
                          >活动价：{{ item.ext.promotion_price }}</span
                        >
                        <span
                          v-if="
                            item.ext.promotion_price &&
                            item.ext.promotion_price != 0 &&
                            item.ext.act_type == 2
                          "
                          >秒杀价：{{ item.ext.promotion_price }}</span
                        >
                        <span
                          v-if="
                            item.ext.member_price && item.ext.member_price != 0
                          "
                          >会员价：{{ item.ext.member_price }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 用户发送的订单 -->
                <div class="doctor-dialogue flex" v-if="item.showType == 9">
                  <img
                    :src="userInfo.head_img ? userInfo.head_img : doc_img"
                    class="doctor-img"
                  />
                  <div class="doctorInfo">
                    <div class="d1 flex between">
                      <span style="font-size: 16px; font-weight: bold"
                        >商品订单</span
                      >
                      <span
                        style="cursor: pointer; color: #4974f5"
                        @click="toOrderdetail(item.ext.order_id)"
                        >查看详情</span
                      >
                    </div>
                    <div class="d2">订单号：{{ item.ext.order_num }}</div>
                    <div class="d2">
                      商品种数：{{ item.ext.order_item_count }}
                    </div>
                    <div class="d2">合计：{{ item.ext.order_price }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom bb flex column">
              <div class="flex align-center">
                <el-upload
                  class="avatar-uploader"
                  action="up"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                  :http-request="sendImg"
                >
                  <img src="@/assets/img/img.svg" />
                </el-upload>
                <el-button
                  type="text"
                  style="margin-left: 8px"
                  @click="recommendShow = true"
                  >推荐商品</el-button 
                >
              </div>
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="textarea"
                class="resizeNone"
                @keyup.enter.native="sendText"
              ></el-input>
              <el-button
                type="primary"
                size="small"
                style="width: 117px; margin-left: auto; margin-top: 8px"
                @click="sendText"
                :disabled="
                  (!textarea && loading) ||
                  !(
                    module_id_array.includes('119') ||
                    module_id_array.includes('999')
                  )
                "
                >发送</el-button
              >
            </div>
          </template>
          <!-- 播放器     -->
          <audio ref="audio">
            <source :src="musicUrl" />
          </audio>
        </div>
      </div>
    </div>
    <el-dialog
      title="选择商品"
      :visible.sync="recommendShow"
      width="600px"
      class="recommendDialog"
    >
      <div class="bb">
        <div class="felx">
          <el-input
            v-model="keyword2"
            placeholder="商品名"
            style="width: 300px; margin-right: 15px"
          ></el-input>
          <el-button type="primary" @click="searchGoodsList">查询</el-button>
        </div>
        <div class="list">
          <div
            class="box flex bb"
            v-for="item in goodsList"
            :key="item.goods_id"
          >
            <el-checkbox v-model="item.checked"></el-checkbox>
            <img :src="item.picture" />
            <div class="flex column column-center">
              <div class="d1" style="align-self: start">
                {{ item.goods_name }}
              </div>
              <div class="d2" style="align-self: start">
                <span>原价：{{ item.price }}</span>
                <span
                  v-if="
                    item.promotion_price &&
                    item.promotion_price != 0 &&
                    item.act_type == 1
                  "
                  >活动价：{{ item.promotion_price }}</span
                >
                <span
                  v-if="
                    item.promotion_price &&
                    item.promotion_price != 0 &&
                    item.act_type == 2
                  "
                  >秒杀价：{{ item.promotion_price }}</span
                >
                <span v-if="item.member_price && item.member_price != 0"
                  >会员价：{{ item.member_price }}</span
                >
              </div>
            </div>
          </div>
          <p style="margin: 10px 0" v-if="goodsList.length == 0">
            暂无搜索商品
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="recommendShow = false">取 消</el-button>
        <el-button type="primary" @click="checkGood">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonUtil from "../../../utils/commonUtil";
import storageUtil from "../../../utils/storage";
import WebIM from "../../../utils/WebIM";
export default {
  name: "service",
  data() {
    return {
      page: 1,
      pageNumber: 200,
      keyword1: "", //查找用户关键词
      keyword2: "", //查找医生关键词
      user_id: "",
      pwd: "",
      doc_img: require("@/assets/img/yisheng.svg"),
      userList: [],
      searchList: [], //查询的医生列表
      searchShow: false, //搜索用户列表可见
      userInfo: {},
      textarea: "",
      dialogueData: [], //对象中的showType值 0是系统消息 1是自己发送的文本消息。2是用户发送的文本消息 3是自己发送的图片消息。4是用户发送的图片消息 5是自己发送的推荐消息 7是用户发送的推荐消息
      musicUrl: "",
      loading: false,
      recommendShow: false, //搜索医生弹窗可见
      goodsList: [],
      module_id_array: window.utils.storage.getter("module_id_array") || [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next();
    if (from.name == "service") {
      location.reload();
    }
  },
  computed: {
    latestMsg() {
      return this.$store.state.shopService.latestMsg; //最后一条收到的消息
    },
    historyList() {
      return this.$store.state.shopService.historyList; //保存的历史记录
    },
    error() {
      return this.$store.state.consult.error; //异常
    },
  },
  watch: {
    latestMsg(newValue, oldValue) {
      newValue = JSON.parse(JSON.stringify(newValue));
      let con_username = newValue.from; //接收到的消息的用户账号
      if (con_username == this.userInfo.con_username) {
        console.log(1);
        if (newValue.contentsType == "TEXT" && !newValue.ext.type) {
          //收到普通文本
          newValue.showType = newValue.from == this.user_id ? 1 : 2;
          this.dialogueData.push(newValue);
        } else if (newValue.contentsType == "TEXT" && newValue.ext.type == 8) {
          //推荐商品
          newValue.showType = newValue.from == this.user_id ? 5 : 7;
          this.dialogueData.push(newValue);
        } else if (newValue.contentsType == "TEXT" && newValue.ext.type == 9) {
          //用户发送的订单
          newValue.showType = 9;
          this.dialogueData.push(newValue);
        } else if (
          newValue.contentsType == "IMAGE" &&
          newValue.type == "chat" //图片消息
        ) {
          newValue.showType = newValue.from == this.user_id ? 3 : 4;
          this.dialogueData.push(newValue);
        } else if (newValue.contentsType == "VOICE") {
          //语音消息
          newValue.showType = 6;
          newValue.isSet = true;
          this.$set(newValue, "playing", false);
          this.dialogueData.push(newValue);
        }
        this.$nextTick(() => {
          let element = this.$refs.dialogBox;
          element.scrollTop = element.scrollHeight;
        });
      } else {
        //判断是否是新用户发来的信息 是的话需要添加进列表,如果列表里面没有 就查询列表并插入
        let index = this.userList.findIndex((ele) => {
          //判断该消息属于列表的哪一条
          return ele.con_username == con_username;
        });
        if (index != -1) {
          let userInfo = JSON.parse(JSON.stringify(this.userList[index]));
          userInfo.msg_time = commonUtil.formatTime(newValue.time);
          userInfo.active = true;
          this.userList.splice(index, 1);
          this.userList.unshift(userInfo);
        } else {
          //查询用户信息并且新加入到列表中
          this.getuserInfoByConUserName(con_username);
        }
      }
    },
    error(newValue, oldValue) {
      this.$confirm("已有其他人登录环信，是否重新登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
      })
        .then(() => {
          window.location.reload();
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.getUserList().then(() => {
      let { con_password, con_username } =
        window.utils.storage.getter("userInfo");
      this.user_id = con_username;
      this.pwd = con_password;
      this.loginIM();
      // this.getuserInfoByConUserName('ihtestuser128866')
    });
  },
  methods: {
    getUserList() {
      let data = {
        page: this.page,
        pageNumber: this.pageNumber,
      };
      return new Promise((resolve, reject) => {
        this.axios
          .post("/store/shop/getUserList", data)
          .then((res) => {
            let list = res.data.list;
            list.forEach((ele) => {
              if (ele.msg_time) {
                ele.msg_time = commonUtil.formatTime(ele.msg_time);
              }
              ele.current = false;
              let count = sessionStorage.getItem(
                "group_shop" + ele.con_username
              );
              ele.active = count && count > 0 ? true : false;
            });
            this.userList = list;
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    loginIM() {
      return new Promise((resolve, reject) => {
        if (WebIM.conn.isOpened(true)) {
          // console.log('环信已经登陆')
          resolve();
        } else {
          let options = {
            user: this.user_id,
            pwd: this.pwd,
            appKey: WebIM.config.appkey,
            apiUrl: WebIM.config.apiURL,
            success: (res) => {
              console.log("商城客服环信登录成功");
              // store.commit("consult/login");
              resolve(res);
            },
            error: (err) => {
              console.log("商城客服环信登录失败");
              reject(err);
            },
          };
          window.WebIM.conn.open(options);
        }
      });
    },
    //input为空时 清除搜索列表
    clearPatient(e) {
      if (!e) {
        this.searchShow = false;
      }
    },
    //选择患者
    selectUser(item, type = 1) {
      this.searchShow = false;
      item.active = false;
      sessionStorage.removeItem("group_shop" + item.con_username);
      this.userList.forEach((ele) => {
        ele.current = ele.user_id == item.user_id ? true : false;
      });
      this.userInfo = item;
      this.getHistory();
      if (type == 2) {
        //从搜索列表里过来的 需要判断是否在会话列表里面 不在的话就要补上
        let index = this.userList.findIndex((ele) => {
          //判断该消息属于列表的哪一条
          return ele.con_username == item.con_username;
        });
        if (index != -1) {
          let userInfo = JSON.parse(JSON.stringify(this.userList[index]));
          userInfo.active = true;
          this.userList.splice(index, 1);
          this.userList.unshift(userInfo);
        } else {
          //加入到列表中
          item.id = 0;
          this.userList.unshift(item);
        }
      }
    },
    //发送文字
    sendText() {
      if (!this.textarea) return;
      this.textarea = this.textarea.replace(/(\r|\n)/gi, "");
      this.loading = true;
      let id = window.WebIM.conn.getUniqueId(); // 生成本地消息id
      let msg = new window.WebIM.message("txt", id); // 创建文本消息
      let option = {
        msg: this.textarea, // 消息内容
        to: this.userInfo.con_username,
        roomType: false, // 群聊类型，true时为聊天室，false时为群组
        ext: {}, // 扩展消息
        success: (res) => {
          let obj = {
            time: new Date().getTime(),
            data: this.textarea,
            showType: 1, //自己发送的文本消息
            to: this.userInfo.con_username,
            contentsType: "TEXT",
            type: "chat",
            from: this.user_id,
            ext: {},
          };
          this.$store.commit("shopService/addNewMsg", { msg: obj });
          this.dialogueData.push(obj);
          this.sendCustomerService(1, this.textarea);
          let info = this.userList.find((ele) => {
            //判断该消息属于列表的哪一条
            return ele.con_username == this.userInfo.con_username;
          });
          info.msg_time = commonUtil.formatTime(new Date().getTime());
          this.$nextTick(() => {
            this.textarea = "";
            let element = this.$refs.dialogBox;
            element.scrollTop = element.scrollHeight;
            this.loading = false;
          });
        },
        fail: function () {
          this.loading = false;
          console.log("failed");
        },
      };
      msg.set(option);
      WebIM.conn.send(msg.body);
    },
    //检查图片
    beforeAvatarUpload(file) {
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/bmp"
      ) {
        this.$message.error("文件格式不正确");
        return false;
      }
      if (file.size > 1024 * 1024 * 10) {
        this.$message.error("文件格式不正确");
        return false;
      }
    },
    //发送图片
    sendImg(e) {
      let url = URL.createObjectURL(e.file);
      let id = WebIM.conn.getUniqueId();
      let msg = new WebIM.message("img", id);
      //拼接环信需要的file
      let file = {
        data: e.file,
        filename: e.file.name,
        filetype: e.file.type,
        url: url,
      };
      let option = {
        apiUrl: WebIM.config.apiURL,
        file: file,
        to: this.userInfo.con_username, // 接收消息对象
        roomType: false,
        onFileUploadError: (err) => {
          // 消息上传失败
          this.$message.error("图片上传失败" + err);
        },
        onFileUploadComplete: (res) => {
          // 图片上传成功
          let url = res.uri + "/" + res.entities[0].uuid;
          let obj = {
            time: res.timestamp,
            url: url,
            showType: 3, //自己发的图片消息
            to: this.userInfo.con_username,
            contentsType: "IMAGE",
            type: "chat",
            from: this.user_id,
          };
          this.dialogueData.push(obj);
          this.$store.commit("shopService/addNewMsg", { msg: obj });
          this.sendCustomerService(2);
          let info = this.userList.find((ele) => {
            //判断该消息属于列表的哪一条
            return ele.con_username == this.userInfo.con_username;
          });
          info.msg_time = commonUtil.formatTime(res.timestamp);
          this.$nextTick(() => {
            let element = this.$refs.dialogBox;
            element.scrollTop = element.scrollHeight;
          });
        },
      };
      msg.set(option);
      WebIM.conn.send(msg.body);
    },
    formatDate(time) {
      return commonUtil.formatTime(time);
    },
    //获取历史记录
    getHistory() {
      this.dialogueData = [];
      this.loading = true;
      let list = this.historyList.find((ele) => {
        return ele.con_username == this.userInfo.con_username;
      });
      //如果vuex里面有 直接用vuex里的记录 否则去获取新的
      if (list) {
        if (list.msgs.length == 0) {
          this.loading = false;
          return false;
        }
        let msgs = JSON.parse(JSON.stringify(list.msgs));
        this.filterHistory(msgs);
      } else {
        let options = {
          queue: this.userInfo.con_username,
          isGroup: false,
          count: 1000,
          success: (msgs) => {
            //将获得的历史记录放入vuex
            let newMsgs = JSON.parse(JSON.stringify(msgs));
            this.$store.commit("shopService/setHistoryList", {
              msgs: newMsgs,
              con_username: this.userInfo.con_username,
            });
            if (msgs.length == 0) {
              this.loading = false;
              return false;
            }
            this.filterHistory(msgs);
          },
          fail: (err) => {
            console.log("历史记录调用失败");
            console.log(err);
            this.loading = false;
          },
        };
        window.WebIM.conn.fetchHistoryMessages(options);
      }
    },
    //处理历史记录
    filterHistory(msgs) {
      msgs.forEach((newValue) => {
        if (newValue.contentsType == "TEXT" && !newValue.ext.type) {
          //收到普通文本
          newValue.showType = newValue.from == this.user_id ? 1 : 2;
          this.dialogueData.push(newValue);
        } else if (newValue.contentsType == "TEXT" && newValue.ext.type == 8) {
          //推荐商品
          newValue.showType = newValue.from == this.user_id ? 5 : 7;
          this.dialogueData.push(newValue);
        } else if (newValue.contentsType == "TEXT" && newValue.ext.type == 9) {
          //用户发送的订单
          newValue.showType = 9;
          this.dialogueData.push(newValue);
        } else if (
          newValue.contentsType == "IMAGE" &&
          newValue.type == "chat" //图片消息
        ) {
          newValue.showType = newValue.from == this.user_id ? 3 : 4;
          this.dialogueData.push(newValue);
        } else if (newValue.contentsType == "VOICE") {
          //语音消息
          newValue.showType = 6;
          newValue.duration = newValue.length;
          this.$set(newValue, "playing", false);
          this.dialogueData.push(newValue);
          this.setAudioHistory(newValue);
        }
      });
      this.$nextTick(() => {
        let element = this.$refs.dialogBox;
        element.scrollTop = element.scrollHeight;
        this.loading = false;
      });
    },

    setAudioHistory(item) {
      if (item.isSet) {
        return;
      }
      let index = this.dialogueData.findIndex((ele) => {
        return ele.id == item.id;
      });
      let options = {
        url: item.url,
        headers: {
          Accept: "audio/mp3",
        },
        onFileDownloadComplete: (response) => {
          let objectUrl = WebIM.utils.parseDownloadResponse.call(
            WebIM.conn,
            response
          );
          this.$set(this.dialogueData[index], "url", objectUrl);
        },
        onFileDownloadError: function () {
          console.log("音频历史记录保存失败");
        },
      };
      WebIM.utils.download.call(WebIM.conn, options);
    },
    //查询用户信息并且新加入到列表中
    getuserInfoByConUserName(con_username) {
      let data = {
        con_username,
      };
      this.axios
        .post("/store/shop/getUserInfoByConUserName", data)
        .then((res) => {
          let info = res.data;
          info.msg_time = commonUtil.formatTime(info.msg_time);
          info.active = true;
          info.current = false;
          this.userList.unshift(info);
        });
    },
    //播放音乐
    playAudio(item, index) {
      this.dialogueData.forEach((ele) => {
        ele.playing = false;
      });
      this.$set(this.dialogueData[index], "playing", true);
      let audio = this.$refs.audio;
      audio.src = item.url;
      if (audio.paused) {
        // 开始播放当前点击的音频
        audio.load();
        audio.oncanplay = () => {
          audio.play();
        };
      } else {
        audio.pause();
        item.play = false;
      }
      audio.onended = () => {
        this.$set(this.dialogueData[index], "playing", false);
      };
      audio.onerror = () => {
        console.log("onerror: " + audio.error.code);
        console.log(audio.src);
      };
    },
    //告知后端已发送消息
    sendCustomerService(type, content = "") {
      let data = {
        user_id: this.userInfo.user_id,
        type,
      };
      if (content) {
        data.content = content;
      }
      this.axios.post("/store/shop/sendCustomerService", data);
    },
    //搜索用户
    searchPatient() {
      if (!this.keyword1) {
        this.$message.error("请输入患者名字！");
        return false;
      }
      let data = {
        keywords: this.keyword1,
        page: this.page,
      };
      this.axios
        .post("/store/shop/searchUser", data)
        .then((res) => {
          let list = res.data.list;
          list.forEach((ele) => {
            if (ele.msg_time) {
              ele.msg_time = commonUtil.formatTime(ele.msg_time);
            }
            let count = sessionStorage.getItem("group_shop" + ele.con_username);
            ele.active = count && count > 0 ? true : false;
          });
          this.searchList = list;
          this.searchShow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //搜索商品列表
    searchGoodsList() {
      if (!this.keyword2) {
        this.$message.error("请输入商品信息！");
        return false;
      }
      let data = {
        keywords: this.keyword2,
        pageNumber: 20,
        page: 1,
      };
      this.axios.post("/store/shop/getShopGoodsList", data).then((res) => {
        let list = res.data.list;
        list.forEach((ele) => {
          ele.checked = false;
          //开启sku的时候 要显示价格范围
          if (ele.sku_list.length > 0 && ele.open_spec == 1) {
            let sku_list = ele.sku_list;
            let arr1 = sku_list.map((ele) => {
              return ele.price;
            });
            let arr2 = sku_list.map((ele) => {
              return ele.member_price;
            });
            let arr3 = sku_list.map((ele) => {
              return ele.promotion_price;
            });
            let arr4 = sku_list.map((ele) => {
              return ele.act_type;
            });
            let price_min = Math.min(...arr1).toFixed(2);
            let price_max = Math.max(...arr1).toFixed(2);
            if (price_max != price_min) {
              ele.price = `${price_min} - ${price_max}`;
            } else {
              ele.price = price_min;
            }
            arr2 = arr2.filter((ele) => {
              return ele > 0;
            });
            arr3 = arr3.filter((ele) => {
              return ele > 0;
            });
            if (arr2.length > 0) {
              let member_price_min = Math.min(...arr2).toFixed(2);
              let member_price_max = Math.max(...arr2).toFixed(2);
              if (member_price_min && member_price_min == member_price_max) {
                ele.member_price = member_price_min;
              } else {
                ele.member_price = `${member_price_min} - ${member_price_max}`;
              }
            } else {
              ele.member_price = "0.00 - 0.00";
            }

            let promotion_price_min = Math.min(...arr3).toFixed(2);
            let promotion_price_max = Math.max(...arr3).toFixed(2);
            if (
              promotion_price_min &&
              promotion_price_min == promotion_price_max
            ) {
              ele.promotion_price = promotion_price_min;
            } else {
              ele.promotion_price = `${promotion_price_min} - ${promotion_price_max}`;
            }

            ele.act_type = Math.max(...arr4);
          }
        });
        this.goodsList = list;
      });
    },
    //选择商品
    checkGood() {
      let arr = this.goodsList.filter((ele) => {
        return ele.checked == true;
      });
      if (!arr || arr.length == 0) {
        this.recommendShow = false;
        return;
      }
      let length = arr.length;
      if (length > 3) {
        this.$message.error("最多选择3个商品！");
        return;
      }
      arr.forEach((ele) => {
        this.sendGood(ele);
      });
      this.recommendShow = false;
      this.goodsList = [];
      this.keyword2 = "";
    },
    //发送推荐商品
    sendGood(item) {
      let id = window.WebIM.conn.getUniqueId(); // 生成本地消息id
      let msg = new window.WebIM.message("txt", id); // 创建文本消息
      let ext = {
        type: 8,
        title: item.goods_name,
        goods_id: item.goods_id.toString(),
        picture: item.picture,
        price: item.price.toString() || "",
        promotion_price: item.promotion_price.toString() || "",
        member_price: item.member_price.toString() || "",
        act_type: item.act_type,
      };
      let option = {
        msg: "", // 消息内容
        to: this.userInfo.con_username,
        roomType: false, // 群聊类型，true时为聊天室，false时为群组
        ext: ext,
        success: (res) => {
          let obj = {
            time: new Date().getTime(),
            data: this.textarea,
            showType: 5, //自己发送的商品消息
            to: this.userInfo.con_username,
            contentsType: "TEXT",
            type: "chat",
            from: this.user_id,
            ext: ext,
          };
          this.$store.commit("shopService/addNewMsg", { msg: obj });

          this.dialogueData.push(obj);
          this.sendCustomerService(3);
          let info = this.userList.find((ele) => {
            //判断该消息属于列表的哪一条
            return ele.con_username == this.userInfo.con_username;
          });
          info.msg_time = commonUtil.formatTime(new Date().getTime());
          this.$nextTick(() => {
            let element = this.$refs.dialogBox;
            element.scrollTop = element.scrollHeight;
            this.loading = false;
          });
        },
        fail: function (res) {
          this.loading = false;
          console.log(res);
        },
      };

      msg.set(option);
      WebIM.conn.send(msg.body);
    },
    toOrderdetail(id) {
      const { href } = this.$router.resolve({
        path: "/order/orderDetail",
        query: { order_id: id },
      });
      window.open(href, "_blank");
    },
  },

  beforeRouteLeave(to, from, next) {
    window.WebIM.conn.close();
    this.$nextTick(() => {
      next();
    });
  },
};
</script>

<style lang='scss' scoped>
.container {
  box-sizing: border-box;
  background-color: #ffffff;
  height: calc(100vh - 140px);
  padding: 0;
  .left {
    width: 285px;
    height: calc(100vh - 140px);
    overflow: hidden;
    border-right: solid 1px #e9e9e9;
    .top {
      margin-left: 16px;
      button {
        margin-left: 10px;
        margin-right: 15px;
      }
    }
    .count {
      text-align: left;
      font-weight: bold;
      margin: 5px 0;
      margin-left: 16px;
    }
    .doctor_list {
      overflow: auto;
      height: calc(100% - 60px);
      .box {
        line-height: 42px;
        cursor: pointer;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding:10px 16px;
        &.current,
        &:hover {
          background-color: #e9efff;
          .d1 {
            color: #333333;
          }
          .d2 {
          }
        }
        .img_box{
          display: flex;
          align-items: center;
        }
        .d1 {
          color: #666666;
          display: inline-block;
          white-space: nowrap; 
          overflow: hidden;
          text-overflow:ellipsis;

        }
        .d2 {
          color: #c9c9c9;
          min-width: 60px;
          span {
            color: #f24e33;
            opacity: 1;
            &.opacity {
              opacity: 0;
            }
          }
        }
      }
    }
  }
  .right {
    position: relative;
    flex: 1;
    height: calc(100vh - 140px);
    box-sizing: border-box;
    padding: 20px 0;
    .top {
      width: 100%;
      background-color: #4974f5;
      color: #ffffff;
      line-height: 50px;
      box-sizing: border-box;
      padding: 0 10px;
      span {
        margin: 0 30px;
      }
    }
    .dialogBox {
      overflow: auto;
      height: calc(100vh - 330px);
      box-sizing: border-box;
      padding: 15px;
      &::-webkit-scrollbar {
        display: none;
      }
      .time {
        margin-top: 15px;
        text-align: center;
        font-size: 12px;
        color: #9b9b9b;
        line-height: 25px;
      }
      .time + .doctor-dialogue,
      .time + .patient-dialogue {
        margin-top: 0;
      }
      .doctor-dialogue {
        margin: 15px 0;
        position: relative;

        .text {
          margin-left: 15px;
          max-width: 600px;
          padding: 14px;
          background-color: #ffffff;
          box-shadow: 0px 2px 15px 0px #e6e6e6;
          border-radius: 0 24px 24px 24px;
          line-height: 25px;
          color: #666666;
        }

        .my-img {
          height: 150px;
          width: auto;
          margin: 0 10px;
        }
      }

      .patient-dialogue {
        margin: 15px 0;
        position: relative;
        flex-direction: row-reverse;

        .text {
          margin-right: 15px;
          max-width: 600px;
          padding: 14px;
          background-color: #4974f5;
          line-height: 25px;
          color: #ffffff;
          box-shadow: 0px 2px 17px 0px #f0f1f1;
          border-radius: 24px 0 24px 24px;
        }
      }
      .mydoctorInfo {
        margin: 0 15px;
        padding: 12px;
        border-radius: 24px 0 24px 24px;
        background-color: #ffffff;
        box-shadow: 0px 2px 17px 0px #f0f1f1;
        max-width: 500px;
        img {
          width: 57px;
          height: 57px;
          border-radius: 4px;
          margin-right: 5px;
        }
        .d1 {
          margin-bottom: 5px;
          line-height: 25px;
          font-weight: bold;
          text-align: left;
          span {
            margin-right: 10px;
          }
        }
        .d2 {
          margin-bottom: 5px;
          line-height: 25px;
          text-align: left;
          span {
            margin-right: 10px;
          }
        }
        .d3 {
          text-align: left;
          line-height: 25px;
          .s2 {
            color: #71737c;
          }
        }
      }
      .doctorInfo {
        margin: 0 15px;
        padding: 12px;
        border-radius: 0 24px 24px 24px;
        background-color: #e9efff;
        max-width: 500px;
        img {
          width: 54px;
          height: 54px;
          border-radius: 4px;
          margin-right: 5px;
        }
        .d1 {
          margin-bottom: 5px;
          line-height: 25px;
          text-align: left;
          font-weight: bold;
          span {
            margin-right: 10px;
          }
        }
        .d2 {
          margin-bottom: 5px;
          line-height: 25px;
          text-align: left;
          span {
            margin-right: 10px;
          }
        }
        .d3 {
          text-align: left;
          line-height: 25px;
          .s2 {
            color: #71737c;
          }
        }
      }
      .patient-img {
        width: 45px;
        height: 45px;
        border-radius: 100%;
      }

      .doctor-img {
        width: 45px;
        height: 45px;
        border-radius: 100%;
      }
    }
    .bottom {
      position: absolute;
      width: 100%;
      padding: 10px;
      left: 0;
      bottom: 0;
      height: 180px;
      text-align: left;
      border-top: solid 1px #e9e9e9;
      background-color: #ffffff;
      img {
        cursor: pointer;
      }
    }
  }
}
.recommendDialog {
  .content {
    padding: 15px;
  }
  .list {
    height: 500px;
    overflow: auto;
    .box {
      margin: 15px 0;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #cccccc;
      .el-checkbox {
        align-self: center;
      }
      img {
        width: 80px;
        height: 80px;
        border-radius: 5px;
        margin-right: 10px;
        margin-left: 10px;
      }
      .d1 {
        margin-bottom: 5px;
        line-height: 25px;
        text-align: left;
        span {
          margin-right: 10px;
        }
      }
      .d2 {
        margin-bottom: 5px;
        line-height: 25px;
        text-align: left;
        span {
          color: #a0918c;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
<style lang='scss'>
.service {
  .resizeNone {
    textarea {
      border: none;
      padding: 5px 0;
    }
    .el-textarea__inner {
      //el_input中的隐藏属性
      resize: none; //主要是这个样式
      background-color: #f0f3f7;
	    border-radius: 4px;
      padding: 17px;
      box-sizing: border-box;
    }
  }
  .my-img {
    & > img {
      height: 150px;
      width: auto;
      margin: 0 10px;
    }
  }
}
</style>